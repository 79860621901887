@tailwind base;
@tailwind components;

:root {
  --primary: black;
  --secondary: #67e8f9;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 0px;
  border: 0px solid var(--primary);
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: flex;
  list-style: none;
  width: 4px;
  height: 4px;
  background: #67e8f9;
  animation: animate 25s linear infinite;
  bottom: -150px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.circles li:nth-child(1) {
  left: 25%;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  animation-delay: 0s;
  animation-duration: 11s;
}
.circles li:nth-child(11) {
  left: 98%;
  animation-delay: 16s;
  animation-duration: 7s;
}
.circles li:nth-child(12) {
  left: 56%;
  animation-delay: 3s;
  animation-duration: 19s;
}
.circles li:nth-child(13) {
  left: 15%;
  animation-delay: 20s;
  animation-duration: 21s;
}
.circles li:nth-child(14) {
  left: 65%;
  animation-delay: 6s;
  animation-duration: 11s;
}
.circles li:nth-child(15) {
  left: 5%;
  animation-delay: 0s;
  animation-duration: 18s;
}
.circles li:nth-child(16) {
  left: 18%;
  animation-delay: 20s;
  animation-duration: 18s;
}
.circles li:nth-child(17) {
  left: 83%;
  animation-delay: 0s;
  animation-duration: 38s;
}
.circles li:nth-child(18) {
  left: 55%;
  animation-delay: 10s;
  animation-duration: 14s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.8;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0.4; /* Reduced opacity change */
  }
}

/* spinner */

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #67e8f9;
  border-radius: 50%;
  margin: 0 5px;
  animation: spin 1.2s infinite linear;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.9s;
}

.dot:nth-child(5) {
  animation-delay: 1.2s;
}

@keyframes spin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(1);
  }
}

.custom-shadow {
  box-shadow: 0 0 15px #67e8f9;
}

.scrolling-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  padding: 8px;
}

.scrolling-text {
  display: inline-block;
  padding-left: 100%;
  animation: scroll-text 25s linear infinite;
  color: #67e8f9;
  font-size: 0.75rem;
}

@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@tailwind utilities;
